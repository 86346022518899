/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import RecipeDetailStyles from './recipe-detail.module.scss';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Disqus from 'gatsby-plugin-disqus';
import FeaturedImage from '../../components/featured-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { TwitterShareButton, WhatsappShareButton, FacebookShareButton } from 'react-share';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import RecipeSEO from '../../components/seo/structured-data/recipe';
import MediaAds from '../../components/adsense/media-ads';
import ReactMarkdown from 'react-markdown';
import { useWindowDimensions } from '../../utils/generic-utils';

// FIXME: Add typing for Gatsby GraphQL queries.
// TODO: CREATE COMPONENT MODEL
const RecipeDetailTemplate: React.FC<any> = ({ data, pageContext }): ReactElement => {
  const { t } = useTranslation();
  const { height, width } = useWindowDimensions();
  const post = data.strapiRecipes;
  const siteTitle = data.site.siteMetadata.title;
  const disqusConfig = {
    identifier: post.id,
    title: post.title,
  };
  const [tabIndex, setTabIndex] = React.useState(0);
  const ads = [];
  data.allStrapiAmazonAds.nodes.map(node => {
    ads[node.Size] = node.Ad;
  });

  // FIXME: Refer to general styles
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#fa8072',
      },
      secondary: {
        main: '#33dbb7',
      },
    },
  });

  return (
    <Layout title={siteTitle}>
      <SEO
        title={post.title}
        description={post.description}
        image={
          post.with_custom_thumbnail &&
          post.custom_thumbnail &&
          post.custom_thumbnail.childImageSharp &&
          post.custom_thumbnail.childImageSharp.fluid.src
            ? post.custom_thumbnail.childImageSharp.fluid.src
            : post.localImage.childImageSharp.fluid.src
        }
      />
      <RecipeSEO recipe={post}></RecipeSEO>
      <article>
        <FeaturedImage disqusConfig={disqusConfig} post={post} postClass={`post`} />
        <div className={`${RecipeDetailStyles.section_post} ${RecipeDetailStyles.wrap}`}>
          <div className={`${RecipeDetailStyles.post_wrap} ${RecipeDetailStyles.post_wrap_title}`}>
            <ReactMarkdown>{post.description}</ReactMarkdown>
          </div>
          {/* <div
            className={`${RecipeDetailStyles.post_wrap_ads}`}
            dangerouslySetInnerHTML={{
              __html:
                ads[width < 1023 ? '300_250' : '728_90'][
                  Math.floor(Math.random() * ads[width < 1023 ? '300_250' : '728_90'].length)
                ].Code,
            }}
          ></div> */}
          {/* <InArticle /> */}
          {(post.Ingredients_by_elaboration.length > 0 || post.Ingredients.length > 0) && post.utensils.length > 0 && (
            <div className={RecipeDetailStyles.post_wrap}>
              <ThemeProvider theme={theme}>
                <Tabs value={tabIndex} onChange={(e, index) => setTabIndex(index)} centered>
                  <Tab className={RecipeDetailStyles.tabs} label={'Ingredientes'} />
                  <Tab className={RecipeDetailStyles.tabs} label={'Utensilios'} />
                </Tabs>
                <TabPanel value={tabIndex} index={0}>
                  {post.Ingredients.map(ingredient => (
                    <div key={ingredient.id}>
                      <ul className={RecipeDetailStyles.tabs_list_elements}>
                        <li>
                          {ingredient.ingredient.name}{' '}
                          {ingredient.Quantity &&
                            ingredient.unit?.name &&
                            `(${ingredient.Quantity} ${ingredient.unit.name})`}
                        </li>
                      </ul>
                    </div>
                  ))}
                  {post.Ingredients_by_elaboration.map((elaboration, index) => (
                    <div key={index}>
                      <h5>{elaboration.Elaboration}</h5>
                      <ul className={RecipeDetailStyles.tabs_list_elements}>
                        {elaboration.Ingredients.map(ingredient => (
                          <li key={ingredient.id}>
                            {ingredient.ingredient.name} ({ingredient.Quantity}
                            {ingredient.unit.name})
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>
                  <div>
                    <ul className={RecipeDetailStyles.tabs_list_elements}>
                      {post.utensils.map(utensil => (
                        <li key={utensil.id}>{utensil.name}</li>
                      ))}
                    </ul>
                  </div>
                </TabPanel>
              </ThemeProvider>
            </div>
          )}
          {(post.Steps_by_elaboration.length > 0 || post.Steps.length > 0) && (
            <div>
              <div className={RecipeDetailStyles.post_wrap}>
                <h4>Preparación de la receta</h4>
                <div>
                  <ol>
                    {post.Steps.map(step => (
                      <li key={step.id}>{step.Description}</li>
                    ))}
                  </ol>
                </div>
                {post.Steps_by_elaboration.map((elaboration, index) => (
                  <div key={index}>
                    <h5>{elaboration.Elaboration}</h5>
                    <ol>
                      {elaboration.Steps.map(step => (
                        <li key={step.id}>{step.Description}</li>
                      ))}
                    </ol>
                  </div>
                ))}
              </div>
            </div>
          )}
          <div className={`${RecipeDetailStyles.post_wrap} ${RecipeDetailStyles.subtitle}`}>
            <h4>{t('POST_DETAIL_VIDEO_TITLE')}</h4>
          </div>
          {post.source && (
            <div
              className={`${RecipeDetailStyles.post_wrap} ${RecipeDetailStyles.insta}`}
              dangerouslySetInnerHTML={{ __html: post.source.referal }}
            ></div>
          )}
          {/* <div className={`${RecipeDetailStyles.post_wrap_middle_ads}`}>
          </div> */}
          <div
            className={`${RecipeDetailStyles.section_post_authors} ${RecipeDetailStyles.post_authors} ${RecipeDetailStyles.flex}`}
          >
            <div className={RecipeDetailStyles.author_label}>
              <span>{t('AUTHOR_INFO_TITLE')}</span>
            </div>
            <AniLink fade duration={1.2} to={post.chef.path} className={RecipeDetailStyles.item_link_overlay}>
              <div className={`${RecipeDetailStyles.author_wrap} ${RecipeDetailStyles.flex}`}>
                {post.chef.avatar && post.chef.avatar.childImageSharp && (
                  <div
                    className={RecipeDetailStyles.author_profile_image}
                    style={{
                      backgroundImage: `url(${post.chef.avatar.childImageSharp.fluid.src})`,
                    }}
                  ></div>
                )}
                <div className={RecipeDetailStyles.author_content}>
                  <h4 className={`${RecipeDetailStyles.is_bio} ${RecipeDetailStyles.no_cover_image}`}>
                    {post.chef.alias}
                  </h4>
                  <p>{post.chef.description}</p>
                </div>
              </div>
            </AniLink>
          </div>
          <div className={RecipeDetailStyles.post_meta}>
            <div className={RecipeDetailStyles.post_share}>
              <a className={RecipeDetailStyles.copy}>
                <TwitterShareButton url={data.site.siteMetadata.siteUrl + post.fields.path}>
                  <FontAwesomeIcon className={RecipeDetailStyles.global_svg} icon={faTwitter} size="1x" />
                </TwitterShareButton>
              </a>
              <a className={RecipeDetailStyles.copy}>
                <WhatsappShareButton url={data.site.siteMetadata.siteUrl + post.fields.path}>
                  <FontAwesomeIcon className={RecipeDetailStyles.global_svg} icon={faWhatsapp} size="1x" />
                </WhatsappShareButton>
              </a>
              <a className={RecipeDetailStyles.copy}>
                <FacebookShareButton url={data.site.siteMetadata.siteUrl + post.fields.path}>
                  <FontAwesomeIcon className={RecipeDetailStyles.global_svg} icon={faFacebook} size="1x" />
                </FacebookShareButton>
              </a>
            </div>
          </div>
        </div>
      </article>
      <aside className={RecipeDetailStyles.section_prev_next}>
        <div className={RecipeDetailStyles.prev_next_wrap}>
          {pageContext.next && (
            <AniLink
              fade
              duration={1.2}
              to={pageContext.next.fields.path}
              className={`${RecipeDetailStyles.prev_post} ${
                pageContext.next.with_custom_thumbnail && !pageContext.next.custom_thumbnail
                  ? RecipeDetailStyles.tag_hash_post_orange
                  : ''
              } ${
                pageContext.next.with_custom_thumbnail && !pageContext.next.custom_thumbnail
                  ? RecipeDetailStyles.no_image
                  : RecipeDetailStyles.is_image
              }`}
            >
              <div
                className={RecipeDetailStyles.prev_next_image}
                style={{
                  backgroundImage: `url(${
                    pageContext.next.with_custom_thumbnail &&
                    pageContext.next.custom_thumbnail &&
                    pageContext.next.custom_thumbnail.childImageSharp &&
                    pageContext.next.custom_thumbnail.childImageSharp.fluid &&
                    pageContext.next.custom_thumbnail.childImageSharp.fluid.src
                      ? pageContext.next.custom_thumbnail.childImageSharp.fluid.src
                      : pageContext.next.localImage.childImageSharp.fluid.src
                  })`,
                }}
              ></div>
              <section className={RecipeDetailStyles.prev_next_title}>
                <h5>{t('NEWEST_POST')}</h5>
                <h3>{pageContext.next.title}</h3>
              </section>
            </AniLink>
          )}
          {pageContext.previous && (
            <AniLink
              fade
              duration={1.2}
              to={pageContext.previous.fields.path}
              className={`${RecipeDetailStyles.prev_post} ${
                pageContext.previous.with_custom_thumbnail && !pageContext.previous.custom_thumbnail
                  ? RecipeDetailStyles.tag_hash_post_green
                  : ''
              } ${
                pageContext.previous.with_custom_thumbnail && !pageContext.previous.custom_thumbnail
                  ? RecipeDetailStyles.no_image
                  : RecipeDetailStyles.is_image
              }`}
            >
              <div
                className={RecipeDetailStyles.prev_next_image}
                style={{
                  backgroundImage: `url(${
                    pageContext.previous.with_custom_thumbnail &&
                    pageContext.previous.custom_thumbnail &&
                    pageContext.previous.custom_thumbnail.childImageSharp &&
                    pageContext.previous.custom_thumbnail.childImageSharp.fluid &&
                    pageContext.previous.custom_thumbnail.childImageSharp.fluid.src
                      ? pageContext.previous.custom_thumbnail.childImageSharp.fluid.src
                      : pageContext.previous.localImage.childImageSharp.fluid.src
                  })`,
                }}
              ></div>
              <section className={RecipeDetailStyles.prev_next_title}>
                <h5>{t('PREVIOUS_POST')}</h5>
                <h3>{pageContext.previous.title}</h3>
              </section>
            </AniLink>
          )}
        </div>
      </aside>
      <div className={RecipeDetailStyles.section_disqus}>
        <div id={RecipeDetailStyles.disqus_thread}>
          <Disqus className={RecipeDetailStyles.post_wrap} config={disqusConfig} />
        </div>
      </div>
    </Layout>
  );
};

interface ITabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: ITabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      className={RecipeDetailStyles.tabs_content}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <p>{children}</p>}
    </div>
  );
}

export default RecipeDetailTemplate;
export const pageQuery = graphql`
  query RecipeDetailById($id: String!) {
    site {
      siteMetadata {
        title
        author
        siteUrl
      }
    }
    allStrapiAmazonAds {
      nodes {
        Size
        Ad {
          Code
          Category
          id
        }
      }
    }
    strapiRecipes(id: { eq: $id }) {
      id
      url_video
      title
      description
      fields {
        path
      }
      Ingredients {
        ingredient {
          name
          id
        }
        Quantity
        id
        unit {
          name
          description
        }
      }
      Ingredients_by_elaboration {
        id
        Elaboration
        Ingredients {
          Quantity
          id
          ingredient {
            name
          }
          unit {
            name
            description
          }
        }
      }
      Steps {
        Description
        Step
        id
      }
      Steps_by_elaboration {
        Elaboration
        Steps {
          Description
          Step
          id
        }
      }
      utensils {
        id
        name
      }
      localImage {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      with_custom_thumbnail
      custom_thumbnail {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      published_at
      duration
      difficulty {
        name
      }
      categories {
        id
        name
        path
      }
      source {
        name
        recipe
        referal
        date(formatString: "DD, MMM y", locale: "es")
        dateISO: date
      }
      chef {
        id
        path
        alias
        description
        avatar {
          childImageSharp {
            fluid {
              src
            }
          }
        }
      }
    }
  }
`;
