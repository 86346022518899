/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

// FIXME: Add typing for Gatsby GraphQL queries.
// TODO: CREATE COMPONENT MODEL
/**
 * Returns the recipe image src
 * @param recipe the recipe node from strapi
 */
export const generateRecipeImage = (recipe: any): string => {
  return recipe.with_custom_thumbnail &&
    recipe.custom_thumbnail &&
    recipe.custom_thumbnail.childImageSharp &&
    recipe.custom_thumbnail.childImageSharp.fluid.src
    ? recipe.custom_thumbnail.childImageSharp.fluid.src
    : recipe.localImage.childImageSharp.fluid.src;
};

/**
 * Reduce an array of ingredients or an array of array of ingredents into an array of strings representing the ingredients
 * @param ingredients an array of ingredients
 */
export const ingredientsFlatten = (recipe: any): string[] => {
  const ingredientResult = (e: any) => `${e.Quantity || ''} ${e.unit?.name || ''} ${e.ingredient.name}`;
  return recipe.Ingredients.map(ingredientResult).concat(
    recipe.Ingredients_by_elaboration.flatMap((e: any) => e.Ingredients.map(ingredientResult)),
  );
};

/**
 * Generates the recipe steps from a recipe or a subsection
 * @param steps recipe steps from strapi
 */
export const generateSteps = (steps: any[]): Record<string, unknown>[] => {
  return steps?.map(s => ({
    '@type': 'HowToStep',
    text: s.Description,
  }));
};

/**
 * Generates the recipe steps separated from sections
 * @param steps recipe steps of elaboration from strapi
 */
export const generateSectionSteps = (stepsElaborations: any[]): Record<string, unknown>[] => {
  return stepsElaborations?.map(s => ({
    '@type': 'HowToSection',
    name: s.Elaboration,
    itemListElement: generateSteps(s.Steps),
  }));
};
