import { graphql, useStaticQuery } from 'gatsby';
import React, { ReactElement } from 'react';
import { generateRecipeImage, ingredientsFlatten, generateSteps, generateSectionSteps } from '../../../utils';

// FIXME: TRY NOT TO USE GATSBY STRUCTURE ON REUSABLE COMPONENTS
// TODO: CREATE COMPONENT MODEL
// TODO: Missing Duration and recipeCuisine fields
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Recipe: React.FC<any> = ({ recipe }): ReactElement => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `,
  );
  const data = JSON.stringify({
    '@context': 'https://schema.org/',
    '@type': 'Recipe',
    name: recipe.title,
    image: site.siteMetadata.siteUrl + generateRecipeImage(recipe),
    author: {
      '@type': 'Person',
      name: recipe.chef.alias,
    },
    datePublished: recipe.source.dateISO,
    description: recipe.title,
    keywords: recipe.categories?.map(e => e.name).join(','),
    recipeCategory: recipe.categories[0]?.name,
    recipeIngredient: ingredientsFlatten(recipe),
    recipeInstructions: generateSteps(recipe.Steps)?.concat(generateSectionSteps(recipe.Steps_by_elaboration)),
  });
  return <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: data }} />;
};

export default Recipe;
